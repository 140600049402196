.preview-component {
  text-align: center;
}
.ui.button.avatar-editor {
  margin: auto;
  display: inline-block;
  font-size: 1em;
  line-height: 1.7em !important;
  padding: 0.59em;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  background-color: rgb(182, 177, 166);
  color: white;
  vertical-align: middle;
  cursor: pointer;
}

.ui.button.avatar-editor.editor-image {
  padding: 0;
}
