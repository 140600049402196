.editable-component:focus {
  border: 1px solid #ccc;
  background-color: #fff;
}

.editable-component {
  color: #333;
  padding: 10px;
  border: 1px solid transparent;
  background-color: transparent;
  line-height: 1.37em;
  box-sizing: content-box;
  border-radius: 5px;
}

.ui.inverted.light-grey.segment {
  background-color: #efefef;
  min-height: 100px;
}

[contenteditable]:empty {
  height: 1.37em;
}

[contenteditable]:empty:before {
  content: attr(placeholder);
  color: #c7c7c7;
  display: block; /* For Firefox */
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

.editable-component[placeholder]:empty:before {
  content: attr(placeholder);
  color: #999999;
}
