#App,
#root,
#main-container {
  height: 100%;
}

.content-container {
  height: calc(100% - 51.3px);
}

.ui.fluid.container.body-container {
  position: relative;
  width: auto;
  height: 100%;
}

.ui.form .field > label {
  font-weight: normal;
  color: rgba(97, 98, 99, 0.88) !important;
}

.ReactVirtualized__Table__row .edit-cell-no-overflow,
.edit-cell-no-overflow {
  overflow: visible !important;
}

.ReactVirtualized__Grid__innerScrollContainer .ReactVirtualized__Table__row {
  overflow: visible !important;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.clickable {
  cursor: pointer;
}
