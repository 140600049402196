/* .registration-settings .ui.table.basic.very tr td {
  border-color: transparent !important;
} */


.ui.basic.segment.active-reg-row {
  /* background-color: #f8f7fc !important */
  background: #f9fafb !important;
  border-color: rgba(34, 36, 38, .15) !important;
  box-shadow: 0 2px 25px 0 rgba(34, 36, 38, .05) inset !important;
  border-color: rgba(34, 36, 38, .15) !important;
  border-width: 1px 0 1px 0 !important;
  border-style: solid !important;
}

.ui.segment.inactive-reg-row {
  padding-bottom: 0;
}