/* https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Using_CSS_animations */
/* https://css-tricks.com/making-css-animations-feel-natural/ */
/* https://stackoverflow.com/questions/20790305/how-to-make-the-last-keyframe-of-your-animation-stay-after-animation-is-finish */
/* http://jsfiddle.net/jkneb/9uRKt/ */
/* https://davidwalsh.name/css-slide */
.App .ui.inverted.olive.menu .active.item {
  background-color: rgba(255, 255, 255, 0.16) !important;
}

.App .ui.inverted.menu .item,
.ui.inverted.menu .item > a:not(.ui) {
  color: rgba(255, 255, 255, 1);
}

.App .desktop-menu-item i.icon::before,
.desktop-menu-subitem i.icon::before {
  opacity: 0.75;
}

.App .ui.vertical.inverted.menu .menu .item,
.ui.vertical.inverted.menu .menu .item a:not(.ui) {
  color: rgba(255, 255, 255, 1);
}

.ui.header > .image.icon-image:not(.icon),
.ui.header > img.icon-image {
  width: 1.6em;
}

#main-container .ui.container.body-container {
  width: auto;
  position: relative;
  height: 100%;
}

.ui.container.fluid.vertical-sidebar {
  width: 244px;
  height: 100%;
  position: fixed;
}

#desktop-vertical-sidebar-container {
  overflow-y: auto;
}

.desktop-menu-item i.icon::before,
.desktop-menu-subitem i.icon::before {
  opacity: 0.5;
}

.ui.compact.vertical.menu.desktop-menu {
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  #root #main-container.ui.fluid.container {
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
  .ui.container.vertical-sidebar {
    margin-right: 0em !important;
  }
  .ui.container.body-container {
    margin-right: 0em !important;
  }
  .ui.container#main-container > .ui.container {
    margin: 0 !important;
  }

  .ui.container.fluid.vertical-sidebar {
    width: 244px !important;
  }
}

.vertical-sidebar {
  transition: all 0.4s ease-in-out;
}

#main-container .ui.container.vertical-sidebar.sidebar-open {
  /* visible */
  /* width: 244px !important; */
  margin-left: 0px !important;
}

/* .sidebar-open .menu-text {
  display: inherit;
}

.sidebar-closed .menu-text {
  display: none;
} */

.desktop-menu-item i.icon.parent-hover-only-icon {
  display: none;
}
#desktop-vertical-sidebar-container:hover i.icon.parent-hover-only-icon {
  display: inherit;
}

#main-container .ui.container.vertical-sidebar.sidebar-closed {
  /* completely offscreen */
  /* width: 64px !important; */
  margin-left: -244px !important;
}

#main-container .ui.container.body-container {
  transition: all 0.4s ease-in-out;
}

#main-container .ui.container.body-container .menu.sub-menu {
  transition: all 0.4s ease-in-out;
}

#main-container .ui.container.body-container.body-sidebar-open {
  /* visible */
  margin-left: 244px !important;
}

#main-container .ui.container.body-container.body-mobile {
  width: 100% !important;
}

#root #main-container.ui.fluid.container .body-container.body-sidebar-closed {
  /* completely offscreen */
  /* margin-left: 64px !important; */
  margin-left: 0px !important;
}

.ui.vertical.menu.desktop-menu .menu .item {
  margin-left: 1.537143em;
  font-size: inherit;
}

.sub-menu {
  overflow: hidden;
}
.ui.vertical.menu .item .menu.sub-menu .active.item {
  font-weight: normal;
}

.sub-menu.open-sub-menu {
  height: inherit !important;
  display: inherit !important;
}

.sub-menu.closed-sub-menu {
  height: 0px !important;
  margin: 0 !important;
  /* animation-name: hideSub; */
}

/* @keyframes hideSub {
  0% {
    display: none;
  }
  100% {
    display: inherit;
  }
} */
