body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
.App {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .ui.container.fluid {
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
}

/* DEMO STYLE  */

/* * {
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
} */

.demo8-outer {
  background-color: #eee;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  font: 28px/1em "Helvetica";
  display: flex;
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}

.demo8 {
  margin: auto;
  width: 320px;
  height: 400px;
}

.demo8-item {
  position: absolute;
  width: 320px;
  height: 90px;
  overflow: visible;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 4px;
  color: rgb(153, 153, 153);
  line-height: 96px;
  padding-left: 32px;
  font-size: 24px;
  font-weight: 400;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.link {
  position: absolute;
  color: rgb(76, 76, 76);
  text-decoration: none;
  font: 14px/1em "Helvetica";
  padding: 10px;
  top: 0;
  left: 0;
}

.cr-form .ui.text.container {
  max-width: 32em !important;
}

.cr-form .ui.form .field {
  margin-bottom: 3em;
}

.ui.modal.modal-form {
  max-width: 400px;
}

.ui.dimmer {
  background-color: rgb(51, 51, 51, 0.9);
}

.program-form .ui.placeholder.segment .column .field {
  max-width: auto !important;
}

.ui.place-holder.segment {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: none;
  max-width: initial;
  -webkit-animation: none;
  animation: none;
  overflow: visible;
  padding: 1em 1em; */
  /* min-height: 18rem; */
  /* background: #f9fafb;
  border-color: rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 25px 0 rgba(34, 36, 38, 0.05) inset; */
}

.inline-schedule .react-datepicker__day--selected {
  background-color: inherit;
  color: inherit;
}

.inline-schedule .react-datepicker__day--highlighted {
  background-color: #29b9bb;
  color: #fff;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

/* ========================= */

.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker {
  font-size: 1em;
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.ui.form .day-of-week-section input {
  width: auto !important;
}

.ui.placeholder.segment .button,
.ui.placeholder.segment .field,
.ui.placeholder.segment textarea,
.ui.placeholder.segment > .ui.input {
  /* max-width: 15rem; */
  margin-left: inherit !important;
  margin-right: inherit !important;
}

.gray-bg {
  background: #f9fafb;
  border-color: rgba(34, 36, 38, 0.15);
  box-shadow: 0 2px 25px 0 rgba(34, 36, 38, 0.05) inset;
}
