.Table {
  width: 100%;
  margin-top: 15px;
}
.headerRow,
.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
}
.oddRow {
  background-color: #fafafa;
}
.headerColumn {
  text-transform: none;
}
.exampleColumn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkboxLabel {
  margin-left: 0.5rem;
}
.checkboxLabel:first-of-type {
  margin-left: 0;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}
