.ui.basic.segment.buildable-field-container {
  border-radius: 0.28571429rem;
}

.ui.basic.segment.buildable-field-container:hover {
  background: hsl(0, 0%, 91%);
}

.ui.segment.is-editing {
  border: 1px solid #ccc;
}