.stepper-menu {
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;
}

.stepper-menu.ui.icon.menu .item {
  width: 33.334%;
}


.App .ui.table td.active,
.App .ui.table tr.active {
  background-color: #fbfbfb !important;
}